import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Article/articleLayout.js";
import Carousel from "../../../../components/Carousel";
import { graphql } from "gatsby";
import ArticleCarouselItem from "../../../../components/Article/articleCarouselItem";
export const pageQuery = graphql`
 {
  carousel: allFile(filter: {relativeDirectory: {eq:"digital/melange/carousel"}}, sort: {fields: name, order: ASC}){
      nodes{
        name
        childImageSharp{
          fluid(quality: 100){
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
 }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Melange is a fashion brand that was inspired by the need to balance trends with culture and aesthetics. The word “Melange” captures this, it has a French root and it means a mixture or combination of different trends as related to fashion.`}</h2>
    <h2>{`This brand is designed to cater for the chic and fashion conscious woman. She wants her style to be in tune with the current trends, yet timeless.`}</h2>
    <p>{`The objective was to design a logo that is classy and beautiful. The logo should be representative of the chic yet timeless style of Melange’s target audience.`}</p>
    <h4><span parentName="h4" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "710px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.563380281690144%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0UlEQVQoz61S2QqEMAzs/3+a4A8oXiiIWO/7nCWBFndX3X0wMCRmpjlsBR42se87B+TPcMcdNUonVHBlnweutCqvJ9y2DcuycNz3PaZp0t/zPDOUEUegHPnjJrrgOI6I4xhZlsGyLFRVhTzPmUuSBL7vw3EcuK4Lz/MgpWRPuuP0uiB1KsuSBVEUYRgGBEHAhaiBaZocG4aBMAyRpils28a6rucFiVBkXddomoYnLIqCc9RArd+2LbquYw3hbeWzH391UXe5r0v59TT+hXj6Yb8Ary4RyxDO8dcAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Melange",
          "title": "Melange",
          "src": "/static/0786e4a1e17c121e36ba20e80dd2b19a/911ab/melange.png",
          "srcSet": ["/static/0786e4a1e17c121e36ba20e80dd2b19a/911ab/melange.png 710w"],
          "sizes": "(max-width: 710px) 100vw, 710px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></h4>
    <p>{`The minimalistic style was selected to keep the design timeless. The combination of the simple wordmark and swirl captures the feel of “Melange” being high class yet relaxed and fun.`}</p>
    <Carousel marginBottom={50} mdxType="Carousel">
  {props.data.carousel.nodes.map(image => <ArticleCarouselItem fluid={image.childImageSharp.fluid} mdxType="ArticleCarouselItem" />)}
    </Carousel>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      